import { Contract, Presentation } from '../types.ts';

// eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
export const getOneOfEachBySpot = (items: Contract[] | Presentation[]) => {
    const itemsBySpot =
        // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
        items.reduce<Record<string, Presentation[] | Contract[]>>((acc, item) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!acc[item.spotOption]) {
                acc[item.spotOption] = [];
            }
            acc[item.spotOption].push(item);
            return acc;
        }, {});

    const filteredItems = Object.values(itemsBySpot).flatMap((spotItems): Contract[] => {
        const runningItems = spotItems.filter(item => item.status === 'running');
        const successOrFailedItems = spotItems.find(item => item.status === 'success' || item.status === 'failed');
        return successOrFailedItems ? [...runningItems, successOrFailedItems] : runningItems;
    });

    return filteredItems;
};
