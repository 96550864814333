import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as Sentry from '@sentry/react';
import { trpcOffertool as trpc } from '../../utils/trpc.ts';
import { Mixpanel } from '../../utils/setupMixpanel.ts';
import { Status } from '../components/Status.tsx';
import { DealItems } from '../types.ts';
import { TrashIcon } from '../components/Icons.tsx';
import { DeleteOfferModal } from '../components/DeleteOfferModal.tsx';

export function Overview() {
    const { dealId } = useParams();
    const intl = useIntl();
    if (!dealId) {
        Sentry.captureException('No dealId provided', { tags: { page: 'Overview' } });
        throw new Error("Can't render DealView without dealId");
    }
    const [items, setItems] = useState<DealItems[] | undefined>(undefined);
    const {
        data: itemsS3,
        isLoading: isLoadingS3,
        isError: isErrorS3,
        refetch: refetchCalculations,
    } = trpc.deals.getCalculations.useQuery({ dealId });

    const {
        data: itemsDb,
        isLoading,
        isError,
        refetch: refetchOffers,
    } = trpc.deals.getOfferOverviewDetails.useQuery({ dealId }, { refetchInterval: 10000 });

    const { data: deal } = trpc.deals.getDeal.useQuery({ dealId }, { enabled: !!dealId });

    const { mutateAsync: deleteOffer, isPending: isPendingDelete } = trpc.deals.deleteOffer.useMutation({
        onSuccess: async () => Promise.all([refetchCalculations(), refetchOffers()]),
    });

    const [deleteOfferId, setDeleteOfferId] = useState<string>();

    useEffect(() => {
        if (itemsDb && itemsS3) {
            const mergedItems = itemsS3.map(itemS3 => {
                const itemDb = itemsDb.find(i => i.offerId === itemS3.calculationId);
                if (itemDb) {
                    return itemDb;
                } else {
                    const offerError = itemS3.status === 'failed' ? { offerError: itemS3.error } : {};
                    return {
                        dealId,
                        offerId: itemS3.calculationId,
                        offerName: itemS3.name,
                        offerStatus: itemS3.status,
                        offerCreatedAt: itemS3.createdDate,
                        offerValidUntil: '',
                        isV2: itemS3.isV2,
                        ...offerError,
                    };
                }
            });
            setItems(mergedItems);
        }
    }, [itemsDb, itemsS3, dealId]);

    useEffect(() => {
        if (deal) {
            Sentry.setTags({
                dealId: deal.id,
                companyName: deal.name,
            });

            Mixpanel.setDeal({ dealId: deal.id, company: deal.name });
        }
    }, [deal]);

    useEffect(() => {
        if (isError) {
            Sentry.captureException('Error fetching offers', { tags: { page: 'Overview' } });
        }
        if (isErrorS3) {
            Sentry.captureException('Error fetching calculations', { tags: { page: 'Overview' } });
        }
    }, [isError, isErrorS3]);

    const deleteOfferCallback = useCallback(async (): Promise<void> => {
        if (deleteOfferId) {
            await deleteOffer({ dealId, offerId: deleteOfferId });
            Mixpanel.track('Offer deleted', { 'Offer Id': deleteOfferId });
        }
    }, [deleteOfferId, dealId, deleteOffer]);

    return (
        <div>
            <div className="my-8">
                <h1>{intl.formatMessage({ id: 'deals.overview.subHeading' }, { dealId })}</h1>
                <Link to={`/deals/${dealId}/offers/create`} relative="path" className="btn btn-sm btn-primary mt-8">
                    {intl.formatMessage({ id: 'deals.createOfferCTA' })}
                </Link>
                <OverviewTable isLoading={isLoading} items={items} isError={isError} onDeleteOffer={setDeleteOfferId} />
            </div>
            <DeleteOfferModal
                isOpen={!!deleteOfferId}
                onConfirm={deleteOfferCallback}
                isPendingDelete={isPendingDelete}
                onClose={() => setDeleteOfferId(undefined)}
            />
        </div>
    );
}

const OverviewTable = ({
    isLoading,
    items,
    isError,
    onDeleteOffer,
}: {
    isLoading: boolean;
    isError: boolean;
    items: DealItems[] | undefined;
    onDeleteOffer: (offerId: string) => void;
}) => {
    const intl = useIntl();

    if (isError) {
        return <div className="overflow-x-auto mt-8">{intl.formatMessage({ id: 'deals.overview.error' })}</div>;
    }

    if (!isLoading && items?.length === 0) {
        return <div className="overflow-x-auto mt-8">{intl.formatMessage({ id: 'deals.overview.empty' })}</div>;
    }

    return (
        <div className="overflow-x-auto mt-8">
            <table className="table">
                <thead>
                    <tr className="text-base">
                        <td> {intl.formatMessage({ id: 'deals.overview.table.header.status' })}</td>
                        <td> {intl.formatMessage({ id: 'deals.overview.table.header.createdAt' })}</td>
                        <td> {intl.formatMessage({ id: 'deals.overview.table.header.name' })}</td>
                        <td> {intl.formatMessage({ id: 'deals.overview.table.header.actions' })}</td>
                    </tr>
                </thead>
                <tbody>
                    {isLoading && !items ? (
                        <>
                            <tr className="skeleton h-2 w-full">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </>
                    ) : (
                        items?.map(item => (
                            <tr key={item.offerId} className="hover">
                                <td>
                                    {item.offerStatus === 'failed' ? (
                                        <div
                                            className="tooltip hover:tooltip-open
                                                     tooltip-error tooltip-right text-sm"
                                            data-tip={
                                                item.offerError ??
                                                intl.formatMessage({ id: 'deals.overview.table.status.errorNotKnown' })
                                            }
                                        >
                                            <Status type={item.offerStatus} />
                                        </div>
                                    ) : (
                                        <Status type={item.offerStatus} />
                                    )}
                                </td>
                                <td>
                                    <p className="text-base">{`${intl.formatDate(item.offerCreatedAt, { dateStyle: 'short' })}, ${intl.formatTime(item.offerCreatedAt)}`}</p>
                                </td>
                                <td>
                                    {item.isV2 ? (
                                        <Link
                                            className="text-base font-medium"
                                            to={`../offers/${item.offerId}/summary`}
                                            relative="path"
                                        >
                                            {item.offerName}
                                        </Link>
                                    ) : (
                                        <Link
                                            className="text-base font-medium"
                                            to={`../calculations/${item.offerId}/slides`}
                                            relative="path"
                                        >
                                            {item.offerName}
                                        </Link>
                                    )}
                                </td>
                                <td className="flex">
                                    <Link
                                        to={`../offers/${item.offerId}/viewConfig`}
                                        relative="path"
                                        className="btn btn-sm btn-default mr-4"
                                    >
                                        {intl.formatMessage({ id: 'deals.overview.table.actions.viewConfig' })}
                                    </Link>
                                    <button
                                        onClick={() => {
                                            onDeleteOffer(item.offerId);
                                        }}
                                        className="btn btn-sm bg-warning hover:bg-warning-content text-warning-content hover:text-white"
                                    >
                                        <TrashIcon width={20} height={20} />
                                    </button>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};
