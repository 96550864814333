import mixpanel, { Dict } from 'mixpanel-browser';
import { config } from '../config.ts';

const url = new URL(window.location.href);
const isAnalyticsEnabled = url.searchParams.get('analyticsEnabled') === 'true';

export const initMixpanel = () => {
    if (config.mixpanelEnabled && config.mixpanelProjectToken && isAnalyticsEnabled) {
        mixpanel.init(config.mixpanelProjectToken, { track_pageview: 'full-url' });
    }
};

export const analytics = {
    identify: (id: string) => {
        if (config.mixpanelEnabled && isAnalyticsEnabled) {
            mixpanel.identify(id);
        }
    },
    track: (name: string, props: Dict) => {
        if (config.mixpanelEnabled && isAnalyticsEnabled) {
            mixpanel.track(name, props);
        }
    },
    trackLinks: (query: string, name: string, props: Dict) => {
        if (config.mixpanelEnabled && isAnalyticsEnabled) {
            mixpanel.track_links(query, name, props);
        }
    },
    setDeal: ({ dealId, company }: { dealId: string; company?: string }) => {
        if (config.mixpanelEnabled && isAnalyticsEnabled) {
            mixpanel.register_once(
                {
                    'Deal Id': dealId,
                    Company: company,
                },
                'None',
                { persistent: false },
            );
        }
    },
    offerIncrement: () => {
        if (config.mixpanelEnabled && isAnalyticsEnabled) {
            mixpanel.people.increment('Offers created');
        }
    },
};

export const Mixpanel = analytics;
