import * as XLSX from 'xlsx';
import { z } from 'zod';
import { Load } from '@trawa-energy/offer-tool-api/deals/schemas';

export const headerRowSchema = z.array(z.string());

export const validateArray = (items: unknown[], itemSchema: z.ZodType, itemDataTypeName: string) => {
    const failedItems = items
        .map((x, index) => ({
            index,
            parseResult: itemSchema.safeParse(x),
        }))
        .filter((x): x is { index: number; parseResult: z.SafeParseError<unknown> } => !x.parseResult.success);

    if (failedItems.length > 5) {
        throw new Error(
            `Found ${failedItems.length} invalid entries out of ${items.length} for ${itemDataTypeName}, e.g.: ${failedItems[0].parseResult.error.message}. First error is at index: ${failedItems[0].index}`,
        );
    } else if (failedItems.length > 0) {
        throw new Error(
            `Found ${failedItems.length} invalid entries out of ${items.length} for ${itemDataTypeName}. Indexes are: ${failedItems.map(x => x.index).join(', ')}. First error is: e.g.: ${failedItems[0].parseResult.error.message}`,
        );
    }
};

export const parseLoadFile = async (file: Blob) => {
    try {
        const fileContent = await file.arrayBuffer();
        const workbook = XLSX.read(fileContent, { type: 'buffer' });

        // Assume the first sheet contains your data
        const firstSheetName = workbook.SheetNames[0];
        if (!firstSheetName) {
            throw new Error('No sheet found');
        }
        const worksheet = workbook.Sheets[firstSheetName] as XLSX.WorkSheet | undefined;
        if (!worksheet) {
            throw new Error('Failed to get worksheet by name');
        }
        // Convert the sheet to an array of objects
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Assume the first row contains column names
        const headerRow = headerRowSchema.parse(sheetData[0]);

        // Remove the first row (column names)
        const dataRows = (sheetData.slice(1) as unknown[][]).filter(row => row.length > 0);

        const { datetime, kWh } = Object.fromEntries(
            headerRow.map((rowName, i) => [rowName, dataRows.map(row => row[i])]),
        );

        const dateTimeSchema = z.string().datetime({ offset: true });
        const kWhSchema = z.number();
        if (!Array.isArray(datetime)) throw new Error('datetime is not an array');
        validateArray(datetime, dateTimeSchema, 'datetime');
        if (!Array.isArray(kWh)) throw new Error('kWh is not an array');
        validateArray(kWh, kWhSchema, 'kWh');

        if (datetime.length !== kWh.length) throw new Error('datetime and kWh must have the same length');

        return { success: true, data: { datetime, kWh } as Load } as const;
    } catch (error) {
        console.error(error);
        return {
            success: false,
            error: error instanceof Error ? error : new Error('Unknown error'),
        } as const;
    }
};
