import { trpcOffertool as trpc } from '../../utils/trpc.ts';
import { useCallback, useMemo } from 'react';
import { Contract, ContractTemplates, Presentation, PresentationTemplates } from '../types.ts';
import { getOneOfEachBySpot } from '../utils/getOneOfEachBySpot.ts';

export const useOfferExport = ({
    dealId,
    offerId,
}: {
    dealId: string;
    offerId: string;
}): {
    contractTemplates: ContractTemplates[];
    contracts: Contract[];
    exportContractFolderUrl: string | undefined;
    exportContractFolderName: string | undefined;
    generateOfferExportContract: typeof generateOfferExportContract;
    isGeneratingContract: boolean;
    presentations: Presentation[];
    presentationTemplates: PresentationTemplates[];
    exportPresentationFolderUrl: string | undefined;
    exportPresentationFolderName: string | undefined;
    generateOfferPresentation: typeof generateOfferPresentation;
    isGeneratingPresentation: boolean;
} => {
    const { data: contracts, refetch: refetchContracts } = trpc.deals.getOfferContracts.useQuery(
        {
            dealId,
            offerId,
        },
        { enabled: !!dealId && !!offerId },
    );
    const { data: presentations, refetch: refetchPresentations } = trpc.deals.getOfferPresentations.useQuery(
        {
            dealId,
            offerId,
        },
        {
            enabled: !!dealId && !!offerId,
            retry: false,
        },
    );
    const { data: contractTemplates } = trpc.deals.getOfferExportContractTemplateTypes.useQuery();

    const { data: slidesTemplates } = trpc.deals.getOfferExportPresentationTemplateTypes.useQuery();

    const { mutateAsync: generateOfferExportContract, isPending: isGeneratingContract } =
        trpc.deals.generateOfferExportContract.useMutation({ onSuccess: () => pollGetContracts() });

    const { mutateAsync: generateOfferPresentation, isPending: isGeneratingPresentation } =
        trpc.deals.generateOfferExportPresentation.useMutation({
            onSuccess: () => pollGetPresentations(),
        });

    const { data: exportContractFolder, refetch: refetchContractFolder } =
        trpc.deals.getOfferContractsFolderUrl.useQuery({ dealId }, { enabled: !!dealId });

    const { data: exportPresentationFolder, refetch: refetchPresentationFolder } =
        trpc.deals.getOfferPresentationsFolderUrl.useQuery({ dealId }, { enabled: !!dealId });

    const pollGetPresentations = useCallback(async () => {
        const resultPresentations = await refetchPresentations();
        // Check if any items have the "running" status
        const isAnyRunning = resultPresentations.data?.some(presentation => presentation.status === 'running');
        if (!exportPresentationFolder?.exportFolderUrl) {
            await refetchPresentationFolder();
        }
        if (isAnyRunning) {
            // If any item is still "running", poll again after 3 seconds
            setTimeout(pollGetPresentations, 3000);
        }
    }, [refetchPresentations, refetchPresentationFolder, exportPresentationFolder]);

    const pollGetContracts = useCallback(async () => {
        const resultContracts = await refetchContracts();
        // Check if any items have the "running" status
        const isAnyRunning = resultContracts.data?.some(contract => contract.status === 'running');
        if (!exportContractFolder?.exportFolderUrl) {
            await refetchContractFolder();
        }
        if (isAnyRunning) {
            // If any item is still "running", poll again after 3 seconds
            setTimeout(pollGetContracts, 3000);
        }
    }, [refetchContracts, refetchContractFolder, exportContractFolder]);

    const contractsOneOfEachSpot = useMemo(() => {
        if (!contracts) {
            return [];
        }
        return getOneOfEachBySpot(contracts);
    }, [contracts]);

    const presentationsOneOfEachSpot = useMemo(() => {
        if (!presentations) {
            return [];
        }
        return getOneOfEachBySpot(presentations);
    }, [presentations]);

    return {
        contractTemplates: contractTemplates ?? [],
        contracts: contractsOneOfEachSpot,
        exportContractFolderUrl: exportContractFolder?.exportFolderUrl,
        exportContractFolderName: exportContractFolder?.exportFolderName,
        generateOfferExportContract,
        isGeneratingContract,
        presentations: presentationsOneOfEachSpot,
        presentationTemplates: slidesTemplates ?? [],
        exportPresentationFolderUrl: exportPresentationFolder?.exportFolderUrl,
        exportPresentationFolderName: exportPresentationFolder?.exportFolderName,
        generateOfferPresentation,
        isGeneratingPresentation,
    };
};
